import React from "react";
import{FooterKontaktSection, FooterKontaktWrapper, TitleKontakt, Text, NumberTel, LinkStyled, BoxFooter, CopyrightText} from "./FooterStyled";
import { StaticImage } from "gatsby-plugin-image";


const Footer = () => {
    return (

<>
<FooterKontaktSection>

    <FooterKontaktWrapper
    whileInView={{y:0, opacity:1}}
    initial={{ y:75, opacity:0}}
    transition={{delay:0.75, duration: 0.75}}
    >

    <TitleKontakt>Skontaktuj się z nami</TitleKontakt>
    <Text>Lecznica dla Zwierząt Sławomir Witczak
    <br/>62-800 Kalisz, ul. Lipowa 17-21
    </Text>

    <NumberTel href="tel:+48627573866">
    
     <StaticImage
     src="./iconTelefon.png"
     alt="telefon do weterynarza w Kaliszu"
     width={24}
     placeholder="blurred"
     />
  
     +48 62 757 38 66
   
    </NumberTel>
 

    <NumberTel href="tel:+48608345265">
  
     <StaticImage
    src="./iconTelefon.png"
    alt="telefon do weterynarza w Kaliszu"
    width={24}
    placeholder="blurred"
    />
    +48 608 345 265
    
     </NumberTel>
        
    <Text>Godziny otwarcia przychodni: <br/>
    poniedziałek - piątek 10:00- 20:00 <br/>
    sobota 9:00 - 14:00 <br/>
    niedziela  - 10:00 - 12:00
    </Text>
  
    </FooterKontaktWrapper>

   <BoxFooter
   whileInView={{y:0, opacity:1}}
   initial={{ y:30, opacity:0}}
   transition={{delay:0.55, duration:0.75}}
>
   <LinkStyled to="/polityka-prywatnosci">Polityka prywatności</LinkStyled>
           
        <CopyrightText>
          © {new Date().getFullYear()}, Wszalkie prawa zastrzeżone
          {` `} 
          <a href="https://www.weterynarz-witczak.pl">Lecznica dla Zwierząt</a>

          <p>
    <a href="https://jigsaw.w3.org/css-validator/check/referer">
        <img style={{border:0, margin:5, width:88, height:31}}
            src="https://jigsaw.w3.org/css-validator/images/vcss-blue"
            alt="Poprawny CSS!" />
    </a>
</p>
     
       </CopyrightText>
     
    
    </BoxFooter>
    
</FooterKontaktSection>
</>
)
  }

export default Footer
