import React from 'react'
import { UslugiPagesWrapper, UslugiPagesSection, UslugiListPages, TextUslugiPages } from './UslugiStyled'

import IconCheck from "./iconuslugi.png"



const UslugiPages = () => {
  
  return ( 
    <>
<UslugiPagesSection>
        <UslugiPagesWrapper>
       
            <UslugiListPages>
          
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/profilaktyka">Profilaktyka
               
               </TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/chirurgia">Chirurgia</TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/radiologia">Radiologia</TextUslugiPages>
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/usg">USG</TextUslugiPages>
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/interna">Interna</TextUslugiPages>
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/kardiologia">Kardiologia</TextUslugiPages>
               
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/dermatologia">Dermatologia</TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/stomatologia">Stomatologia</TextUslugiPages>
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/dietetyka">Dietetyka</TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/poloznictwo-i-rozrod">Położnictwo i rozród</TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/choroby-krolikow-gryzoni">Choroby gryzoni</TextUslugiPages>
            </li>
            
            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/laboratorium">Laboratorium</TextUslugiPages>
            </li>

            <li>
                <span>
                  <img src={IconCheck} alt="check" />
                </span>
               <TextUslugiPages to="/paszporty-i-czipowanie">Paszporty i czipowanie</TextUslugiPages>
            </li>

            
            </UslugiListPages>
        
    </UslugiPagesWrapper>
</UslugiPagesSection>
   </>
  )
}

    
export default UslugiPages
