import styled from "styled-components";
import { motion } from "framer-motion";
import {Link} from "gatsby"

export const UslugiSection = styled.div`
width:100% ;
background-color: ${({ theme }) => theme.colors.white};
`;

export const UslugiWrapper = styled.div`
font-family: 'Montserrat', sans-serif;
max-width: 1534px;
background-color: ${({ theme }) => theme.colors.white};
margin: 0 auto;
padding-left: 5%;
padding-right: 5%;
scroll-margin: 100px;

@media screen and (max-width: 768px) {
scroll-margin: 100px;
padding-left: 5%;
padding-right: 5%;
}

`;

export const BigTitle = styled(motion.h2)`
font-family: 'Montserrat', sans-serif;
font-size:clamp(2.5rem, calc(2.5rem + ((1vw - 0.36rem) * 10.119)), 11rem);
  min-height: 0vw;
display:flex;
justify-content: end;
padding-right:25px;
color:${({ theme }) => theme.colors.lightGreen};
font-weight: 900;
text-shadow: 2px 4px 4px rgba(118, 128, 112, 1);



@media screen and (max-width: 768px) {
  text-shadow: 2px 2px 2px rgba(118, 128, 112, 1);
  padding-top:10px;
  margin-bottom:10px;
  text-align: center;
  padding-right: 0;

}
`;


export const BigTitleSmall = styled(motion.h2)`
font-family: 'Montserrat', sans-serif;
font-size: 110px;
display:flex;
justify-content: center;
color:${({ theme }) => theme.colors.lightGreen};
text-shadow: 2px 4px 4px rgba(118, 128, 112, 1);
font-weight: 900;

@media screen and (max-width: 768px) {
  font-size: 2rem;
  text-shadow: 2px 2px 2px rgba(118, 128, 112, 1);
  padding:10px;
}

`;

export const H3Uslugi = styled.h3`
font-family: 'Montserrat', sans-serif;
font-size: 1.6rem;
line-height: 2rem;
font-weight: 900;
color:#6aa940;


@media screen and (max-width: 768px) {
  font-size: 1.4rem;
}
`;



export const FlexWrapper = styled.div`
display: flex;
justify-content: space-between;


@media screen and (max-width: 768px) {
  display: flex;
  flex-direction: column;
}
`;


export const BoxImage = styled(motion.div)`
padding-top:10px;
padding-right:100px;
width: 50%;
display:flex;
justify-content: end;

@media screen and (max-width: 768px) {
  width: 100%;
  padding-top:10px;
  padding-right: 20px;
}

`;


export const UslugiList = styled.ul`
  font-family: 'Montserrat', sans-serif;
list-style-type: none;
  margin-top: 20px;
  width: 40%;
  
  > li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;

    /*img{
      width:36px;
    }*/
   
    > span {
      margin-right: 40px;
      transform: translateY(2px);
   
    }
  }

  @media screen and (max-width: 768px) {

  width: 100%;

> li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 1rem;
  
  img{
      width:32px;
    }
   
    > span {
      margin-right: 40px;
      transform: translateY(2px);
    }
  }
}
`;

export const TextUslugi = styled(Link)`
  font-family: 'Montserrat', sans-serif;
 list-style-type: none;
  text-decoration: none;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.midleBlack};
    -webkit-transition: transform 0.5s linear;
-moz-transition: transform 0.5s linear;
-ms-transition: transform 0.5s linear;
-o-transition: transform 0.5s linear;
transition: transform 0.5s linear;


:hover{
  color:#6aa940;
}

@media screen and (max-width: 768px) {
  
  font-size: 1.2rem;
}
`;



export const BoxTextPages = styled.div`
font-family: 'Montserrat', sans-serif;
width: 100%;
padding-left: 10%;
padding-top:40px;

@media screen and (max-width: 768px) {
  padding-left: 5%;
  padding-top:10px;
}
`;
export const BoxImages = styled.div`

width:100%;
display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
`

export const Flex = styled.div`
display: flex;
justify-content: space-between;
justify-items: center;
padding-left: 10%;
padding-right:10%;

@media screen and (max-width: 768px) {
  display: flex;
  flex-direction: column;
}

`;


export const ListServices = styled.li`
  font-family: 'Montserrat', sans-serif;
font-size:  clamp(1rem, calc(1rem + ((1vw - 0.36rem) * 0.2381)), 1.2rem);
  min-height: 0vw;
color: ${({ theme }) => theme.colors.midleBlack};
font-weight: 500;
line-height:1.8rem ;
padding-right: 20px;
`;

export const TitleServices = styled.h2`
  font-family: 'Montserrat', sans-serif;
color: ${({ theme }) => theme.colors.lightGreen};
font-size: clamp(1.5rem, calc(1.5rem + ((1vw - 0.36rem) * 0.2381)), 1.7rem);
  min-height: 0vw;
font-weight: 900;

@media screen and (max-width: 768px) {
padding-top:10px;
}
`;


/*   pages */


export const UslugiPagesSection = styled.div`
width:100% ;
`;

export const UslugiPagesWrapper = styled.div`
width: 100%;
max-width: 1534px;
background-color: #fff;
font-family: 'Montserrat', sans-serif;
padding-left: 5%;
padding-right: 5%;
margin: 0 auto;
display: flex;
justify-content: space-between;

@media screen and (max-width: 768px) {
padding-left: 5%;
padding-right: 5%;
}
`;

export const FlexContent = styled.div`


@media screen and (max-width: 768px) {
display: flex ;
flex-direction: column-reverse ;
}
`;

export const BigTitlePages = styled(motion.h1)`
font-family: 'Montserrat', sans-serif;
font-size:clamp(2.5rem, calc(2.5rem + ((1vw - 0.36rem) * 10.119)), 11rem);
  min-height: 0vw;
display:flex;
justify-content: end;
padding-right:25px;
color:${({ theme }) => theme.colors.lightGreen};
font-weight: 900;
text-shadow: 2px 4px 4px rgba(118, 128, 112, 1);



@media screen and (max-width: 768px) {
  text-shadow: 2px 2px 2px rgba(118, 128, 112, 1);
  padding-top:10px;
  margin-bottom:10px;
  text-align: center;
  padding-right: 0;

}
`;
export const BigTitleSmallPages = styled(motion.h1)`
font-family: 'Montserrat', sans-serif;
font-size: 110px;
display:flex;
justify-content: center;
color:${({ theme }) => theme.colors.lightGreen};
text-shadow: 2px 4px 4px rgba(118, 128, 112, 1);
font-weight: 900;

@media screen and (max-width: 768px) {
  font-size: 2rem;
  text-shadow: 2px 2px 2px rgba(118, 128, 112, 1);
  padding:10px;
}

`;



export const UslugiListPages = styled.ul`
max-width:1534px ;
list-style-type: none;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /*width: 40%;*/
  > li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding-right: 10px;
   
    img{
      width:32px;
    }
   
    
 > span {
      margin-right: 15px;
      transform: translateY(2px);

   }  
    
  }


  @media screen and (max-width: 768px) {
  
  display: flex;
  flex-direction: column;
  margin-top:10px;
  padding-left:5%;
  >li {
    margin-bottom: 0.8rem;

    img{
      width:32px;
    }
  }
  }

`;

export const TextUslugiPages = styled(Link)`

list-style-type: none;
  text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.midleBlack};
    -webkit-transition: transform 0.5s linear;
-moz-transition: transform 0.5s linear;
-ms-transition: transform 0.5s linear;
-o-transition: transform 0.5s linear;
transition: transform 0.5s linear;


:hover{
  color:#6aa940;
}


@media screen and (max-width: 768px) {
  
  font-size: 1.2rem;
}

`;

export const TextServices = styled.p`

font-size: clamp(1rem, calc(1rem + ((1vw - 0.36rem) * 0.2381)), 1.2rem);
min-height: 0vw;
color: ${({ theme }) => theme.colors.midleBlack};
line-height:1.8rem;
padding-right: 20px;
`;
