import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages,Flex, BigTitlePages, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const paszportyiczipowanie = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
    title="Paszporty i czipowanie"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący zabiegi chirurgiczne oraz czipowanie i wystawianie paszportów psów i kotów umożliwiająca szybka identyfikacje właścicieli.
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="czip dla zwierząt kalisz, paszport dla zwierząt kalisz"
    />
    
    <Navbar />

    <BigTitlePages style={{justifyContent: 'center',textAlign:'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Paszporty</BigTitlePages>  
         
         <FlexContent>
          <UslugiPages />

 <Flex> 
  
 <StaticImage
    src="../images/paszporty.jpeg"
    alt="Paszporty i czipowanie weterynarz Kalisz"
    width={300}
    placeholder="blurred"
    className="staticimagepages"
    />
 
    <BoxTextPages>

<TitleServices>Oferujemy czipowanie i wystawienie paszportów podczas jednej wizyty.</TitleServices>
<ListServices>Elektroniczne znakowanie (czipowanie) psów i kotów jest jedną z głównych metod zapobiegania bezdomności zwierząt, umożliwiająca szybką identyfikację właścicieli. Polega na bezbolesnym wszczepieniu pod skórę elektronicznego mikroczipa z 15-cyfrowym, unikatowym numerem, a następnie wprowadzeniu danych o zaczipowanym zwierzęciu do Międzynarodowej Bazy Danych SafeAnimal.</ListServices>
<ListServices>Paszport jest dokumentem identyfikacyjnym wystawianym na potrzeby przemieszczania zwierząt domowych pomiędzy państwami członkowskimi UE.</ListServices>

    </BoxTextPages>
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
</>
  )
}

export default paszportyiczipowanie